.testimonials-section {
    padding: 20px 0;
  }
  
  .container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .testimonial-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 45%;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  
  .speech-bubble {
    position: relative;
    background: #f0f0f0;
    color: black;
    border-radius: .4em;
    padding: 20px;
    margin: 10px;
  }
  
  .speech-bubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 15px 15px 15px 0;
    border-color: transparent #f0f0f0 transparent transparent;
    display: block;
    width: 0;
    z-index: 1;
    left: -15px;
    top: 20px;
  }
  
  footer {
    margin-top: 10px;
    font-style: italic;
  }
  
  @media (max-width: 768px) {
    .testimonial-container {
      width: 100%;
    }
  }
  