/* PaymentPage.css */

/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .payment-page {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 100px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }
  
  h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1em;
    margin-bottom: 10px;
  }
  
  /* Form Styles */
  .form-group {
    margin-bottom: 15px;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  select {
    width: calc(100% - 24px);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }
  
  input[type="radio"] {
    margin-right: 10px;
  }
  
  .button-container {
    text-align: right;
    margin-top: 20px;
  }
  
  .button-container button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button-container button:hover {
    background-color: #0056b3;
  }
  
  /* Payment Methods */
  .payment-methods {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .payment-method {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f1f1f1;
  }
  
  .payment-method:hover {
    border-color: #007bff;
    background-color: #e9e9e9;
  }
  
  /* Confirmation Message */
  .confirmation-message {
    text-align: center;
    margin-top: 30px;
  }
  
  .confirmation-message h2 {
    font-size: 1.5em;
    color: #28a745;
  }
  
  .confirmation-message p {
    font-size: 1.2em;
  }
  
  .confetti {
    font-size: 2em;
    margin-top: 20px;
  }

  /* Popup overlay to dull the background */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is on top */
}

/* Popup content box */
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  text-align: center;
}

/* Style for the textarea */
.share-message {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Container for the buttons */
.popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Button styles */
.popup-buttons .btn {
  width: 48%;
}
  