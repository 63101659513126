.typing-container {
    position: relative;
    display: inline-block;
  }
  
  .cursor {
    display: inline-block;
    width: 1px;
    height: 1em;
    background-color: black;
    animation: blink 0.75s step-end infinite;
  }
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  