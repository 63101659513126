.dashboard {
    background-color: black;
    display: grid;
    grid-template-columns: 0.5fr 5fr;
    gap: 20px;
    padding-top: 70px;
  }
  
  .filters {
    border-right: 1px solid #ddd;
    padding-right: 20px;
    overflow-y: auto; /* Allow vertical scrolling */
  }
  
  .filter-section {
    margin-bottom: 20px;
  }
  
  .filter-section h3 {
    margin-bottom: 10px;
  }
  
  .filter-section label {
    display: block;
  }
  
  .projects {
    padding-left: 20px;
    overflow-y: auto; /* Allow vertical scrolling */
  }
  
  .project-list {
    display: flex;
    flex-direction: column;
  }
  
  .project-item {
    display: grid;
    grid-template-columns: 2fr 3fr 1fr;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd; /* Line after each project */
    padding: 50px;
  }
  
  .project-poster img {
    background: 0 0 no-repeat;
    background-size: 100% 100%;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  
  .project-details h2 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .project-details p {
    padding-top: 10%;
  }
  
  .project-tags {
    font-size: 0.9em;
  }
  
  .project-tags .tag {
    display: inline-block;
    margin-right: 5px;
    padding: 2px 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-style: italic;
    font-weight: bold;
  }
  
  .project-info p {
    margin: 5px 0;
  }

  .project-poster img {
    padding-right: 40px;
    max-height: 750px;
  }

  footer {
    grid-column: 1 / -1;
    width: 100%;
  }
  