.faq-page  h1{
    padding-top: 75px;
    color: black;
    text-align: center;
  }
  
  .faq-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: black;
  }
  
  .faq-item {
    margin-bottom: 15px;
  }
  
  .faq-question {
    cursor: pointer;
    font-size: 1.2em;
    font-weight: bold;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }
  
  .faq-answer {
    margin-top: 5px;
    padding: 10px;
    background-color: #e9e9e9;
    border-radius: 5px;
    display: none;
  }
  
  .faq-answer.active {
    display: block;
  }
  