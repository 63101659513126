.container {
    padding: 5px;
    padding-top: 20px;
  }
  
  .my-investments-dashboard {
    text-align: center;
  }
  
  .total-investment {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .chart-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
  }
  
  .chart-item {
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    flex: 1 1 auto;
    margin: 10px;
  }
  