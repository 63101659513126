.footer {
    background-color:#353535;
    padding: 20px 0;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .footer-nav-container,
  .footer-subscription-container {
    width: 45%;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .footer-nav {
    list-style: none;
    padding: 0;
  }
  
  .footer-nav li {
    margin-bottom: 10px;
  }
  
  .footer-nav link {
    text-decoration: none;
    color: #007bff;
  }
  
  .footer-subscription-container form {
    display: flex;
    flex-direction: column;
  }
  
  .footer-subscription-container input[type="email"] {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .footer-subscription-container button {
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .footer-subscription-container button:hover {
    background-color: #0056b3;
  }
  
  .footer-subscription-container p {
    margin: 10px 0;
  }
  
  @media (max-width: 768px) {
    .footer-nav-container,
    .footer-subscription-container {
      width: 100%;
      margin-bottom: 20px;
    }

    .footer form {
      max-width: 100%;
    }
  }
  